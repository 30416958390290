<template>
  <div
    class="d-flex justify-content-center align-items-center h-100"
    v-if="$store.getters['load']"
  >
    <div
      class="spinner-border text-primary"
      role="status"
      style="width: 3rem; height: 3rem"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="home h-100" v-else>
    <div class="container h-100 py-5">
      <div class="row row-wrapper align-items-center justify-content-center mt-5">
        <div class="row wrapper">
          <div
            class="
              col-12
              d-flex
              align-items-center
              justify-content-center
              px-md-1
            "
            data-aos="fade-down"
            data-aos-easing="ease-in-out"
            data-aos-duration="400"
          >
            <img alt="asu" src="~@/assets/images/asu-logo.png" class="asu" />
          </div>

          <div class="col-12 text-center pb-3 ">
            <div
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
            >
              <h3 class="py-2 py-md-3">
                <strong></strong>{{ this.$store.getters["intro"].course_name }}
              </h3>
              <h3>
                <strong></strong>{{ this.$store.getters["intro"].case_name }}
              </h3>
            </div>
          </div>

          <div class="col-12 text-center">
            <div
              class="start-btn btn"
              @click="next"
              data-aos="fade-up"
              data-aos-duration="400"
            >
              <img alt="asu" src="~@/assets/images/btn.png" class="asu" />
              <span class="btn-span">Start</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data(){
    return{
      start_time: null,
      end_time: null
    }
  },
  methods: {
    next() {
      if (this.$store.state.step >= this.$router.options.routes.length - 1) {
        return false;
      } else {
        this.$store.dispatch('next')
      }
    },
  },
  mounted(){
    this.$store.dispatch('allowNavigationStatus')
    this.$store.dispatch( 'setInstructions', '' )
  },
  
};
</script>

<style scoped>
.home {
  position: relative;
}

.wrapper {
  max-height: 500px;
}

@media (max-width: 768px) {
  img {
    max-width: 100px;
  }
}

@media (min-width: 768px) {
  .asu {
    width: 200px !important;
  }
}

</style>
